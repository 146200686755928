import React from 'react';
import styles from './style.module.scss';
import Image from 'next/image';
import Link from 'next/link';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper/modules';

interface NewArrivalsProps {
  data: any;
}

const NewArrivals: React.FC<NewArrivalsProps> = ({ data }) => {
  return (
    <>
      <section className={styles.arrivalsection}>
        <div className="container-fluid">
          <div className={styles.arrivalinner}>
            <div className="wdRow ">
              <div className="wdCol-lg-12">
                <div className={styles.arrivalheading}>
                  <div className={styles.arrivalheadingleft}>
                    <h2>{data?.title}</h2>
                    <p>{data?.subtitle}</p>
                  </div>
                  <Link href="/home-furnishing" className="view-all-btn">
                    View All
                    <Image
                      src="/images/categories/view-all-link-arrow.svg"
                      alt="media"
                      width={17}
                      height={17}
                      quality={100}
                      loading="lazy"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className={styles.arrivalcontent}>
              <div className="collectionsslider new-arrivals-slider">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={12}
                  navigation={true}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 12,
                    },
                    1300: {
                      slidesPerView: 4,
                      spaceBetween: 12,
                    },
                  }}
                  modules={[Navigation]}
                  className="mySwiper"
                >
                  {data?.items?.map((category: any, index: number) => (
                    <SwiperSlide key={index}>
                      <Link href={category.link} className={styles.arrivalcard}>
                        <div className={styles.arrivalimg}>
                          <figure>
                            <Image
                              width={445}
                              height={386}
                              quality={100}
                              loading="lazy"
                              src={category.imageUrl}
                              alt={category.alt}
                              title={category.name}
                            />
                          </figure>

                          {category.isNew && (
                            <span className={styles.arrinewbtn}>New</span>
                          )}
                        </div>
                        <div className={styles.arrivaltext}>
                          <h4>{category.name}</h4>
                          <p>{category.priceOffer}</p>
                        </div>
                      </Link>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewArrivals;
